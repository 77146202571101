<template>
  <div :open="isEditing" class="pt-12">
    <div class="container">
      <h2
        v-blokkli-editable:field_title
        class="text-3xl md:text-4xl mb-10 lg:mb-14"
      >
        {{ title }}
      </h2>
    </div>
    <SVCarousel :is-mock="isEditing">
      <BlokkliField
        v-if="isEditing"
        name="field_slides"
        :list="slides"
        class="border-success-600 border-2 border-dashed p-4 my-4 flex flex-col gap-5 me-14"
      />

      <Slide v-for="(slide, i) in slides" v-else :key="i">
        <BlokkliItem v-bind="slide" />
      </Slide>
    </SVCarousel>
  </div>
</template>

<script lang="ts" setup>
import { Slide } from 'vue3-carousel'
import type { ParagraphCarouselFragment } from '#graphql-operations'

defineProps<{
  slides: ParagraphCarouselFragment['slides']
  title: ParagraphCarouselFragment['title']
}>()

const { isEditing } = defineBlokkli({
  bundle: 'carousel',
  editor: {
    editTitle: (el) => el.querySelector('h2')?.textContent,
  },
})
</script>
