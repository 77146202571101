<template>
  <div class="container" :class="paragraphClassList">
    <h2
      v-blokkli-editable:field_title
      class="text-3xl md:text-4xl mb-10 lg:mb-14"
    >
      {{ title }}
    </h2>
    <div class="grid-container gap-10 mb-12 lg:mb-20">
      <div
        v-for="(item, i) in mediaListTyped"
        :key="`item_${i}`"
        class="col-span-4 sm:col-span-6 md:col-span-4 lg:col-span-4 border hover:border-primary-500 transition-[border-color] ease-in-out duration-250 flex flex-col"
      >
        <div v-if="item.media" class="mb-5 max-h-[200px] overflow-hidden">
          <MediaImage v-bind="item.media" hide-caption />
        </div>
        <h3
          v-if="item.title"
          class="text-xl md:text-2xl px-5 mb-12 hyphens-auto"
        >
          {{ item.title }}
        </h3>
        <a
          class="text-base text-primary-600 font-bold px-5 mb-5 inline-flex mt-auto"
          :href="item?.path"
          download
        >
          {{ $texts('download', 'Download') }}
          <SpriteSymbol
            name="download"
            class="size-6 ml-2 fill-primary-600 transition-all ease-in-out duration-250 max-h-0 [.teaser:hover_&]:max-h-6 -translate-y-0.5 [.teaser:hover_&]:translate-y-0 opacity-0 [.teaser:hover_&]:opacity-100"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphMediaListFragment } from '#graphql-operations'
import { notNullish } from '@vueuse/core'

const props = defineProps<{
  title: ParagraphMediaListFragment['title']
  mediaList: ParagraphMediaListFragment['mediaList']
}>()

const { $texts } = useNuxtApp()

const { options } = defineBlokkli({
  bundle: 'media_list',
  globalOptions: ['spacing'],
  editor: {
    editTitle: (el) => el.querySelector('h2')?.textContent,
  },
})

const mediaListTyped = computed(() => {
  return props.mediaList
    ?.map((item) => {
      if (item && 'image' in item && item.mediaFileUrl?.path) {
        const title =
          item.image && 'alt' in item.image ? item.image.alt : item.name
        return {
          image: item.image,
          title,
          path: item.mediaFileUrl?.path,
          media: item,
        }
      }
      return null
    })
    .filter(notNullish)
})

const paragraphClassList = computed(() => {
  const classList = []

  if (options.value.spacing === 'small') {
    classList.push('py-6', 'lg:py-10')
  } else if (options.value.spacing === 'large') {
    classList.push('py-12', 'lg:py-20')
  }

  return classList
})
</script>
